<script lang="ts" setup>
const categories = useHousing()
</script>
  
<template>
    <div class="w-full md:w-[600px] mx-auto pb-5">
        <div class="text-2xl lg:text-6xl font-bold text-center mb-5 text-white-10 drop-shadow">
            Find din næste bolig her
        </div>
        <div class="flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0 justify-center">
            <NuxtLink v-for="category in categories" :key="category.id" :to="'/search?types=' + category.name"  class="
            flex items-center space-x-1 rounded-md px-3 py-2    
            text-greenish-950  
            
            bg-white-40 
            hover:bg-white-10 transition duration-300 ease-in-out">
                <!-- <component 
                    :is="category.icon" 
                    :size="20" 
                /> -->
                <div>
                    {{ category.text }}
                </div>
            </NuxtLink>
        </div>
    </div>
</template>
