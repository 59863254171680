export const useCities = () => {
    return [
        { name: "København" },
        { name: "Århus" },
        { name: "Odense" },
        { name: "Aalborg" },
        { name: "Esbjerg" },
        { name: "Randers" },
        { name: "Horsens" },
        { name: "Kolding" }
    ]
}
  